
class RegisterInterest {

  constructor() {
    document.getElementById('register-interest-form').addEventListener('submit', this.sendMessage)
  }

  sendMessage($event) {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://hooks.slack.com/services/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", true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    const body = {
      text: `Checkpoint Interest Registration`,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*Application:* Checkpoint`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*Email:* ${$event.target.email.value}`,
          },
        }
      ],
    };

    xhr.send(JSON.stringify(body));

    xhr.onreadystatechange = () => {
      $event.target.email.value = "";
      document.getElementById('interest-received').classList.remove('hidden');
    }

    $event.preventDefault();
  }
}

new RegisterInterest();
